/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-dom-props */
import { WarningRounded } from '@material-ui/icons';
import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { getUnixTime } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBoxArchive } from 'react-icons/fa6';
import { ImCheckboxChecked } from 'react-icons/im';
import { IoSettings } from 'react-icons/io5';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import ItemLoading from './ItemLoading';

import Button from '../common/Button';
import Checkbox from '../common/CheckBox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../common/DropdownMenu';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../common/Tabs';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';

import { resetApplicationState } from '@/shared/reducers/applicationReducer';
import { resetFromScratch } from '@/shared/reducers/fromScratchReducer';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/reduxStore';
import {
  useArchiveProductMutation,
  useLazyGetProductListQuery,
  useLazyGetServiceDetailQuery,
} from '@/shared/slices/productSlice';
import sectionAnimationConfig from '@/utils/config';

interface ProductsListProps {
  setSelectedService: (service: string) => void;
  setRegenerateProduct: (product: any) => void;
}

function ProductsList({
  setRegenerateProduct,
  setSelectedService,
}: ProductsListProps) {
  const {
    page,
    limit: pageLimit,
    products,
    hasMore,
    currentProductId,
    archiver,
  } = useSelector((state: RootState) => state?.products);
  const { t } = useTranslation('translation', { keyPrefix: 'memberArea' });
  const dispatch = useDispatch();
  const elementRef = useRef<HTMLDivElement | null>(null);

  const TabInfo = [
    {
      title: 'products',
      singleButton: t('toArchive'),
      multipleButton: t('multipleButtonToArchive'),
      tabName: t('productTab'),
      filter: 0,
    },
    {
      title: 'archiver',
      singleButton: t('toRevert'),
      multipleButton: t('multipleButtonToRevert'),
      tabName: t('archiverTab'),
      filter: 1,
    },
  ];

  const [isArchiveTab, setIsArchiveTab] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<number>(0);
  const [isMultipleChoiceActive, setIsMultipleChoiceActive] =
    useState<boolean>(false);
  const [isArchiveSuccess, setIsArchiveSuccess] = useState<boolean>(false);
  const [productIdsToHandle, setProductIdsToHandle] = useState<any>([]);
  const [currentTabInfo, setcurrentTabInfoInfo] = useState<any>(TabInfo);

  const [currentTab, setcurrentTab] = useState('products');

  const [getServiceDetail] = useLazyGetServiceDetailQuery();
  const [getProducts] = useLazyGetProductListQuery();
  const [getArchiveProduct] = useArchiveProductMutation();

  const entry = useIntersectionObserver(elementRef, {
    ...sectionAnimationConfig,
  });

  useEffect(() => {
    if (!entry?.isIntersecting) return;
    const newPage = page! + 1;
    dispatch(setProductsState({ page: newPage }));
    getProducts({
      page: newPage,
      limit: pageLimit,
      filter: currentFilter,
    });
  }, [entry?.isIntersecting]);

  useEffect(() => {
    if (!currentProductId) return;
    getServiceDetail({
      productId: currentProductId,
      service: 'description',
    });
  }, [currentProductId]);

  const handleChangeTab = async (filter: number) => {
    const newPage = 1;
    setCurrentFilter(filter);
    setcurrentTab(filter ? 'archiver' : 'products');
    const result = await getProducts(
      {
        page: newPage,
        filter,
        reset: true,
      },
      false
    );
    if (result) setProductIdsToHandle([]);
  };

  const handleProduct = async (productIdList: any, filter: number) => {
    if (!productIdList) return;
    const productArchived = await getArchiveProduct({
      productId: productIdList,
      filter,
    });
    if (!productArchived) setProductIdsToHandle([]);
    handleChangeTab(currentFilter);
    setIsArchiveSuccess(true);
  };

  useEffect(() => {
    if (!archiver) {
      setcurrentTabInfoInfo([
        {
          title: 'products',
          singleButton: t('toArchive'),
          multipleButton: t('multipleButtonToArchive'),
          tabName: t('productTab'),
          filter: 0,
        },
      ]);
      handleChangeTab(0);
      setIsArchiveTab(false);
    } else {
      setcurrentTabInfoInfo(TabInfo);
    }
  }, [archiver]);

  return (
    <div>
      <div className="border border-solid rounded-lg border-greyBorder bg-greyBg text-white flex flex-col items-center mt-5 pt-5 pb-[30px] cursor-pointer">
        <Tabs
          value={currentTab}
          className="flex flex-col items-center w-[400px]"
        >
          <TabsList className="bg-transparent">
            {currentTabInfo.map((tab: any) => (
              <TabsTrigger
                onClick={() => {
                  handleChangeTab(tab.filter);
                  setIsArchiveTab(!!tab.filter);
                }}
                value={tab.title}
                key={nanoid()}
              >
                {tab.tabName}
              </TabsTrigger>
            ))}
          </TabsList>
          {currentTabInfo.map((tab: any) => (
            <TabsContent className="w-full" value={tab.title} key={tab.title}>
              <div className="relative w-full max-h-[500px] overflow-hidden">
                <div className="w-fill max-h-[500px] products-scroll-container overflow-y-auto overflow-x-hidden !rounded-none">
                  {products &&
                    products?.map((product, index) => {
                      const {
                        updated_at: updatedAt,
                        scraping,
                        generated,
                        description,
                      } = product;
                      /**
                       * If last update for this product is longer than 45 minutes but still scraping
                       * it most likely the BE services is exit and keep this loading like forever.
                       * preventLoadingForever will prevent this issue.
                       */
                      const updated = updatedAt ?? 1717572527;
                      const preventLoadingForever = updated
                        ? getUnixTime(new Date()) - updated > 5 * 60
                        : 0;
                      const failedWhenScrapingFail = !scraping && !generated;
                      const failedWhenScrapingTooLong = Boolean(
                        scraping && preventLoadingForever
                      );
                      const productFailed = Boolean(
                        failedWhenScrapingFail || failedWhenScrapingTooLong
                      );
                      const productSuccess = Boolean(
                        generated ||
                          (description &&
                            description !== null &&
                            !scraping &&
                            generated &&
                            !failedWhenScrapingFail &&
                            !failedWhenScrapingTooLong)
                      );
                      const producGenerating =
                        !productFailed && !productSuccess;
                      return (
                        <div
                          key={nanoid()}
                          className={clsx(
                            'flex items-center justify-between py-[15px] px-[20px] text-white',
                            currentProductId &&
                              currentProductId === product.id &&
                              '   border-t border-b   border-b-[#ffffff33] border-t-[#ffffff33]  border-solid border-r-0  !border-l-0 !rounded-none bg-[#ffffff14]'
                          )}
                        >
                          <div className="flex items-center">
                            {isMultipleChoiceActive && (
                              <Checkbox
                                checked={productIdsToHandle?.includes(
                                  product.id
                                )}
                                onCheckedChange={(checked: boolean) => {
                                  if (checked) {
                                    setProductIdsToHandle([
                                      ...productIdsToHandle,
                                      product.id,
                                    ]);
                                  } else {
                                    setProductIdsToHandle(
                                      productIdsToHandle.filter(
                                        (archiverId: number) =>
                                          archiverId !== product.id
                                      )
                                    );
                                  }
                                }}
                                className="me-5 bg-transparent border-solid text-white p-2.5"
                              />
                            )}

                            <div
                              key={product.id}
                              data-id={product.id}
                              className="flex items-center justify-between"
                              onClick={async () => {
                                if (currentProductId === product.id) return;
                                dispatch(resetFromScratch());
                                dispatch(resetApplicationState());
                                dispatch(
                                  setProductsState({
                                    shopifyImport: false,
                                    shopifyHomepageImportTemplate: false,
                                  })
                                );
                                if (productFailed) {
                                  setRegenerateProduct(product);
                                }
                                if (producGenerating || !productFailed) {
                                  dispatch(
                                    setProductsState({
                                      currentProductId: product.id,
                                    })
                                  );
                                  setSelectedService('description');
                                }
                              }}
                              onKeyDown={() => {}}
                              tabIndex={index}
                              role="menuitem"
                            >
                              {producGenerating && (
                                <ItemLoading
                                  name={product?.name}
                                  image={product?.image_url}
                                />
                              )}
                              {productFailed && (
                                <div className="flex items-center">
                                  <WarningRounded className="!text-4xl text-amber-400" />
                                  <div className="inline-flex flex-col ml-2">
                                    <h3 className="m-0 text-base text-amber-400">
                                      {product.name}
                                    </h3>
                                  </div>
                                </div>
                              )}
                              {productSuccess && (
                                <div className="flex">
                                  <div>
                                    {product?.image_url && (
                                      <img
                                        className="product-lil-img !w-8 !h-8"
                                        alt={product.name}
                                        src={product.image_url}
                                      />
                                    )}
                                  </div>
                                  <div className="flex items-center justify-around">
                                    <div>
                                      <h3 className="m-0 text-base flex items-start leading-[18.4px] ">
                                        {product.name}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <DropdownMenu>
                              <DropdownMenuTrigger className="outline-none text-white border-0 bg-transparent flex items-center">
                                <IoSettings />
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="w-56 border bg-zinc-900 text-white border-solid border-black/10">
                                <DropdownMenuGroup>
                                  <DropdownMenuItem
                                    onClick={() =>
                                      handleProduct(
                                        [product?.id],
                                        currentFilter
                                      )
                                    }
                                  >
                                    <FaBoxArchive className="mr-2" />
                                    {tab.singleButton}
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    onClick={() => {
                                      setIsMultipleChoiceActive(
                                        !isMultipleChoiceActive
                                      );
                                      setProductIdsToHandle([]);
                                    }}
                                  >
                                    <ImCheckboxChecked className="mr-2" />
                                    {tab.multipleButton}
                                  </DropdownMenuItem>
                                </DropdownMenuGroup>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </div>
                      );
                    })}
                  {hasMore && (
                    <div ref={elementRef}>
                      <div className="items-center flex py-[15px] px-[20px] text-white cursor-pointer  flex-row">
                        <ItemLoading />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </TabsContent>
          ))}
        </Tabs>
        <Modal
          className="modal-container"
          isOpen={isArchiveSuccess}
          overlayClassName="modal-overlay"
        >
          <div className="max-w-md bg-white rounded-lg md:min-w-full p-7 ">
            <div className="flex items-center justify-center font-semibold text-xl">
              {!currentFilter
                ? `${t('archiveSuccess')}`
                : `${t('revertSuccess')}`}
            </div>
            <div className="flex justify-center mt-4">
              <Button
                className="p-2 px-8 mr-2 text-white border-none rounded-lg bg-primary"
                text="Close"
                type="button"
                onClick={() => setIsArchiveSuccess(false)}
              />
            </div>
          </div>
        </Modal>
      </div>
      {isMultipleChoiceActive && !isArchiveTab && (
        <>
          <div className="flex justify-center items-center mt-5">
            <Button
              onClick={() => handleProduct(productIdsToHandle, currentFilter)}
              className="w-[400px] py-3 rounded font-semibold text-[15px]"
            >
              {t('toMultipleArchive')} ({productIdsToHandle?.length})
            </Button>
          </div>
          <div className="flex justify-center items-center mt-2">
            <Button
              onClick={() => setIsMultipleChoiceActive(false)}
              className="w-[400px] py-3 rounded font-semibold text-[15px]"
            >
              {t('cancel')}
            </Button>
          </div>
        </>
      )}
      {isMultipleChoiceActive && isArchiveTab && (
        <>
          <div className="flex justify-center items-center mt-5">
            <Button
              onClick={() => handleProduct(productIdsToHandle, currentFilter)}
              className="w-[400px] py-3 rounded font-semibold text-[15px]"
            >
              {t('toMultipleRevert')} ({productIdsToHandle?.length})
            </Button>
          </div>
          <div className="flex justify-center items-center mt-2">
            <Button
              onClick={() => setIsMultipleChoiceActive(false)}
              className="w-[400px] py-3 rounded font-semibold text-[15px]"
            >
              {t('cancel')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default ProductsList;
