import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setCancellationState } from '@/shared/reducers/cancellationReducer';
import { useProcessingFeedbackMutation } from '@/shared/slices/subscriptionSlice';

export interface FeedbackDataProps {
  title: string;
  content: string;
}

function useAntiSpam() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [spam, setSpam] = useState<boolean>(true);
  const [processingFeedback] = useProcessingFeedbackMutation();

  const updateCancelationState = (state: Record<string, any>) => {
    dispatch(setCancellationState(state));
  };

  const processingFeedbackFn = async (args: FeedbackDataProps) => {
    updateCancelationState({
      detailError: '',
      stepperNextBtnLoading: true,
    });
    const { title, content } = args;
    const antiSpam =
      content?.split(' ').some((word: string) => {
        const charCount: { [key: string]: number } = {};
        if (word.replace(/\s/g, '').length >= 20) {
          return true;
        }
        return word.split('').some((char) => {
          charCount[char] = (charCount[char] || 0) + 1;
          return charCount[char] > 3;
        });
      }) ?? true;
    if (antiSpam) {
      updateCancelationState({
        detailError: t('cancelation.errors.invalid'),
        stepperNextBtnDisable: true,
        stepperNextBtnLoading: false,
      });
      return;
    }
    if (!content || content.length < 25) {
      updateCancelationState({
        detailError: t('cancelation.detailError'),
        stepperNextBtnDisable: true,
        stepperNextBtnLoading: false,
      });
      return;
    }

    const antiSpamAI =
      (await processingFeedback({
        title,
        content,
      })) ?? {};
    const { data }: any = antiSpamAI ?? {};
    const { spam: spamAI, related, unhelpful, error } = data;
    if (error) {
      updateCancelationState({
        detailError: t('cancelation.errors.notFound'),
        stepperNextBtnDisable: true,
        stepperNextBtnLoading: false,
      });
      return;
    }
    if (spamAI) {
      updateCancelationState({
        detailError: t('cancelation.errors.spam'),
        stepperNextBtnDisable: true,
        stepperNextBtnLoading: false,
      });
      return;
    }
    if (!related || unhelpful) {
      updateCancelationState({
        detailError: t('cancelation.errors.notRelated'),
        stepperNextBtnDisable: true,
        stepperNextBtnLoading: false,
      });
      return;
    }
    setSpam(false);
    updateCancelationState({
      detailError: '',
      stepperNextBtnDisable: false,
      stepperNextBtnLoading: false,
    });
  };

  return [processingFeedbackFn, { spam }] as const;
}

export default useAntiSpam;
