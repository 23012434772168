import { apiSlice } from './apiSlice';

import { CancellationState } from '../reducers/cancellationReducer';

interface CustomerFeedbackProps {
  whyOption: string;
  whyDetail?: string;
  reviewScore?: string;
  reviewDetail?: string;
  expectationsDetail?: string;
  howOfften?: string;
  improvements?: string;
  improvementsResult?: string;
}

interface AcceptOffer {
  offer: number;
}

interface ProcessFeedback {
  title: string;
  content: string;
}

const subscriptionSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserSubscription: build.query<any, void>({
      query: () => ({
        url: `subscription/user`,
        method: 'GET',
      }),
    }),
    userCancelationFeedback: build.mutation<any, CustomerFeedbackProps>({
      query: (feedback) => ({
        url: `subscription/feedback`,
        method: 'POST',
        body: feedback,
      }),
    }),
    acceptOffer: build.mutation<any, AcceptOffer>({
      query: (payload) => ({
        url: `subscription/cancel/offer`,
        method: 'POST',
        body: payload,
      }),
    }),
    processingFeedback: build.mutation<any, ProcessFeedback>({
      query: (payload) => ({
        url: `subscription/processing-feedback`,
        method: 'POST',
        body: payload,
      }),
    }),
    getUserCurrency: build.query<any, void>({
      query: () => ({
        url: `stripe/currency`,
        method: 'GET',
      }),
    }),
    checkout: build.mutation<any, any>({
      query: (checkoutData) => ({
        url: 'stripe/create-checkout-session',
        method: 'POST',
        body: checkoutData,
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetUserSubscriptionQuery,
  useLazyGetUserSubscriptionQuery,
  useCheckoutMutation,
  useProcessingFeedbackMutation,
  useLazyGetUserCurrencyQuery,
  useUserCancelationFeedbackMutation,
  useAcceptOfferMutation,
} = subscriptionSlice;
