import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LuLoader2 } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/common/Button';
import PlusThickIcon from '@/components/common/svg/PlusThickIcon';
import { FeatureFlags, displayFeature } from '@/shared/configs/featureFlags';
import { setApplicationState } from '@/shared/reducers/applicationReducer';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/rootReducers';
import {
  useGetProductListQuery,
  useLazyGetServiceDetailQuery,
} from '@/shared/slices/productSlice';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';
import { Service } from '@/shared/types/service';

interface ServicesMenuProps {
  services: Service[];
  selectedService: string | null;
  setSelectedService: React.Dispatch<React.SetStateAction<string | null>>;
  productDetail: any;
  reviews: any[] | null;
}

function ServicesMenu({
  services,
  selectedService,
  setSelectedService,
  productDetail,
  reviews,
}: ServicesMenuProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'memberArea' });
  const { products, limit } = useSelector(
    (state: RootState) => state?.products
  );
  const { t: langAll } = useTranslation();
  const { currentProductId } = useSelector(
    (state: RootState) => state?.products
  );
  const { user } = useSelector((state: RootState) => state?.auth);
  const dispatch = useDispatch();
  const { active: isGenerateFromScratch, showScratchButton } = useSelector(
    (state: RootState) => state.fromScratch
  );

  // When shopify template loading for the first time, need to freeze the Homepage menu too
  const { isFetching: shopifyDataLoading } = useGetShopifyTemplateDataQuery(
    {
      productId: currentProductId,
      homepage: 0,
    },
    { skip: !currentProductId }
  );
  const { isSuccess: isProductsLoaded } = useGetProductListQuery({
    page: 1,
    limit,
    reset: true,
  });
  const [getServiceDetail] = useLazyGetServiceDetailQuery();
  const displayGenerateFromSratchProduction = displayFeature({
    featureFlag: FeatureFlags?.PRODLY_272_GENERATE_FROM_SCRATCH_20240411,
  });
  const productsData = products ?? [];
  const isProductLoading = !isProductsLoaded && productsData?.length <= 0;

  useEffect(() => {
    if (!selectedService || selectedService === 'reviews') return;
    if (selectedService === 'description' && productDetail?.benefices) {
      dispatch(
        setProductsState({
          shopifyImport: true,
          shopifyHomepageImportTemplate: false,
        })
      );
    }
    getServiceDetail({
      productId: currentProductId,
      service: selectedService,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId, selectedService, productDetail]);

  const isUserPlanStillValid = user?.expiration !== true;

  return (
    <div className="hidden lg:block w-[610px] py-[30px] px-[0px]">
      {displayGenerateFromSratchProduction && !isProductLoading && (
        <Button
          text={langAll('scratch.generateFromScratch')}
          className="bg-primary mb-5 justify-center text-white font-medium text-lg shadow-[0_0px_20px] shadow-primary/50 px-5 py-5 w-full border-none rounded-lg disabled:opacity-20 disabled:shadow-none disabled:cursor-not-allowed"
          icon={<PlusThickIcon className="w-5 mr-4" />}
          iconPosition="before"
          onClick={() => {
            dispatch(setFromScratch({ active: true }));
            dispatch(setApplicationState({ active: false }));
          }}
          disabled={!showScratchButton || !isUserPlanStillValid}
        />
      )}
      {!isProductLoading ? (
        services?.map((service, index) => {
          const isServiceLocked = service.locked || !isUserPlanStillValid;
          const isContentFilled = () => {
            if (!selectedService) return true;
            if (service.locked) return true;
            if (
              service.key === 'description' &&
              productDetail &&
              productDetail.urlValid === false
            ) {
              return true;
            }
            if (service.key === 'homepage') {
              return shopifyDataLoading === false && productDetail?.homepage;
            }
            if (service.key === 'reviews') {
              return !!reviews;
            }
            if (service.key === 'ecomApplication') {
              return true;
            }
            if (service.api_key && productDetail?.[service.api_key]) {
              return true;
            }
            return false;
          };
          const isServicesShow = () => {
            if (service.key === 'description') {
              return true;
            }
            if (service.key === 'reviews') {
              return true;
            }
            if (service.key === 'ecomApplication') {
              return true;
            }
            if (productDetail?.generated) {
              return productDetail?.[service?.api_key ?? ''];
            }
            return true;
          };

          const activeMenu =
            selectedService === service.key && !isGenerateFromScratch;

          return (
            <Button
              key={service.key}
              className={clsx(
                'flex px-5 py-2 justify-between w-full items-center mb-5 border rounded-lg border-solid border-white border-opacity-30 bg-[#D9D9D9] text-white bg-opacity-10 disabled:opacity-30 disabled:cursor-not-allowed',
                activeMenu && '!bg-black !border-primary !text-primary',
                isServiceLocked && ' locked',
                selectedService === service.key && 'selected',
                !isServicesShow() && '!hidden',
                (!isContentFilled() || isServiceLocked) && 'locked opacity-70',
                service.key !== 'ecomApplication' &&
                  !productDetail &&
                  'pointer-events-none opacity-70'
              )}
              disabled={!isContentFilled() || isServiceLocked}
              onClick={() => {
                dispatch(setFromScratch({ active: false }));
                dispatch(setApplicationState({ active: false }));

                switch (service.key) {
                  case 'ecomApplication':
                    dispatch(setApplicationState({ active: true }));
                    dispatch(
                      setProductsState({
                        shopifyImport: false,
                        shopifyHomepageImportTemplate: false,
                      })
                    );
                    break;
                  case 'description':
                    dispatch(
                      setProductsState({
                        shopifyImport: true,
                        shopifyHomepageImportTemplate: false,
                      })
                    );
                    break;
                  case 'homepage':
                    dispatch(
                      setProductsState({
                        shopifyImport: false,
                        shopifyHomepageImportTemplate: true,
                      })
                    );
                    break;
                  default:
                    dispatch(
                      setProductsState({
                        shopifyImport: false,
                        shopifyHomepageImportTemplate: false,
                      })
                    );
                }

                if (productDetail && !service.locked && isContentFilled()) {
                  setSelectedService(service.key);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (productDetail && !service.locked && isContentFilled()) {
                    setSelectedService(service.key);
                  }
                }
              }}
              role="menuitem"
              tabIndex={index}
            >
              <div className="inline-flex items-center">
                <span className="material-symbols-outlined services-icons mr-2">
                  {service.icon}
                </span>
                <h4>{t(`serviceMenus.${service.key}`)}</h4>
                {isServiceLocked && <span className="lock-icon">🔒</span>}
              </div>
              {!isContentFilled() && (
                <LuLoader2 className="animate-spin text-lg mt-[2px] mr-2" />
              )}
            </Button>
          );
        })
      ) : (
        <div>
          {services.map((service) => (
            <div
              className="h-20 animate-pulse mb-5 bg-white/10 rounded"
              key={service.key}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ServicesMenu;
