import { filter, uniqueId } from 'lodash';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VscVerifiedFilled } from 'react-icons/vsc';
import { useSelector } from 'react-redux';

import { FormFieldsSettingProps } from '.';

import Paypal from '@/assets/shopify/paypal.svg';
import RatingStars from '@/components/common/RatingStars';
import { Button } from '@/components/common/shadcn-ui/Button';
import TextLoadingSkeleton from '@/components/common/TextLoadingSkeleton';
import EditableText from '@/components/EditableText';
import GroupDeletable from '@/components/GroupDeletable/GroupDeletable';
import { RootState } from '@/shared/reduxStore';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';

interface ImportProductInfoProps {
  fields: Record<string, any>[];
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportProductInfo({ fields, onInputChange }: ImportProductInfoProps) {
  const { t } = useTranslation();
  const { currentProductId, shopifyHomepageImportTemplate } = useSelector(
    (state: RootState) => state?.products
  );
  const { data: shopifyTemplateData, isFetching: gettingShopifyTemplateData } =
    useGetShopifyTemplateDataQuery(
      {
        productId: currentProductId,
        homepage: shopifyHomepageImportTemplate ? 1 : 0,
      },
      {
        skip: !currentProductId,
      }
    );
  const { shipping } = shopifyTemplateData ?? [];

  const productInfoFields = fields.filter(({ name }) =>
    ['productName', 'productDescription', 'shortBenefits'].includes(name)
  );
  const editableSections = ['shortBenefits'];
  const mainReviewField: any = find(fields, { name: 'mainReview' });
  const totalReviewsField: any = find(fields, { name: 'totalReviews' });
  const shippingField = filter(fields, (field) =>
    field.name.includes('shippingContent')
  );

  const shippingId = shippingField?.map((ship) => ship.id); // [1,2,3]
  const { mainReview } = shopifyTemplateData ?? {};
  return (
    <div className="col-span-12 w-full xl:col-span-6">
      <div className="w-auto rounded-xl bg-white px-2 py-3">
        <div className="flex w-full items-center justify-start gap-1 mb-4">
          <RatingStars />
          <div className="ml-2">
            <EditableText
              {...totalReviewsField}
              onInputChange={onInputChange}
            />
          </div>
        </div>
        <div className="space-y-4">
          {productInfoFields.map((field: any) => {
            if (editableSections.includes(field.name)) {
              return (
                <GroupDeletable groupId={field.id} key={uniqueId()}>
                  <EditableText {...field} onInputChange={onInputChange} />
                </GroupDeletable>
              );
            }
            return (
              <EditableText
                {...field}
                onInputChange={onInputChange}
                key={uniqueId()}
              />
            );
          })}
        </div>
      </div>

      <Button
        type="button"
        className="linear bg-white p-6 w-full rounded-none cursor-pointer text-based outline-none border border-solid border-black shadow-none font-normal tracking-widest text-defaultBlack hover:bg-white"
      >
        {t('shopify.shopifyAddToCart')}
      </Button>
      <Button
        type="button"
        className="linear bg-transparent p-6 my-2 w-full rounded-none cursor-pointer text-xs underline underline-offset-4 outline-none border-0 shadow-none font-normal tracking-widest text-defaultBlack hover:bg-transparent"
      >
        {t('shopify.morePaymentOptions')}
      </Button>
      <GroupDeletable groupId={shippingId}>
        <div className="flex items-center justify-between">
          {shipping?.length > 0 &&
            shipping.map((_: any, index: number) => {
              const contentField = find(fields, {
                name: `shippingContent${index}`,
              }) as FormFieldsSettingProps;
              const imageField = find(fields, {
                name: `shippingImage${index}`,
              }) as FormFieldsSettingProps;
              return (
                <div
                  className="flex flex-col items-center justify-center"
                  key={uniqueId()}
                >
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(imageField?.text)}`}
                    alt=""
                    className="w-7 h-7"
                  />
                  <p className="pt-3 text-center text-xs text-bluePrimary">
                    {contentField?.text}
                  </p>
                </div>
              );
            })}
        </div>
      </GroupDeletable>

      <div className="flex  items-center gap-1 pb-10 text-center sm:flex-row sm:text-left">
        <GroupDeletable groupId={mainReviewField?.id}>
          <div className="w-full">
            <div className="flex w-full items-center justify-center gap-0.5 sm:justify-start">
              <RatingStars starClass="max-w-[12px]" />
            </div>
            {gettingShopifyTemplateData && (
              <TextLoadingSkeleton className="bg-defaultBlack" />
            )}
            {mainReview && (
              <>
                <EditableText
                  {...mainReviewField}
                  onInputChange={onInputChange}
                />
                <p className="relative text-xs font-bold leading-5">
                  <VscVerifiedFilled className="absolute mr-2 inline-block size-4 text-primary" />
                  <span className="pl-5">{mainReview.author}</span>
                </p>
              </>
            )}
          </div>
        </GroupDeletable>
      </div>
    </div>
  );
}

export default ImportProductInfo;
