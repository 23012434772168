/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/no-unescaped-entities */
import jwtDecode from 'jwt-decode';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Animation from './common/Animation';
import Button from './common/Button';
import { Form, FormControl, FormField, FormItem } from './common/Form';
import { Input } from './common/Input';
import AliexpressLogo from './common/svg/AliexpressLogo';
import AmazonLogo from './common/svg/AmazonLogo';
import BlinkStarIcon from './common/svg/BlinkStarIcon';

function TryIt() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  const onSubmit = (values) => {
    const { amazonUrl } = values;

    const token = localStorage.getItem('productly');
    if (!token) {
      if (amazonUrl) {
        localStorage.setItem('amazonUrl', amazonUrl);
      }
      navigate('/login');
    } else {
      try {
        const decodedUser = jwtDecode(token);
        const currentTime = new Date().getTime() / 1000;

        if (decodedUser.exp < currentTime) {
          console.warn('Token expired');
          localStorage.removeItem('productly');
          localStorage.setItem('amazonUrl', amazonUrl);
          navigate('/login');
        } else {
          navigate('/app', { state: { amazonUrl, showModal: true } });
        }
      } catch (error) {
        console.error('Invalid token', error);
        localStorage.setItem('amazonUrl', amazonUrl);
        navigate('/login');
      }
    }
  };
  const form = useForm({
    defaultValues: {
      amazonUrl: '',
    },
  });

  return (
    <Animation className="z-[2] text-white">
      <div className="items-center flex flex-col justify-center sm:p-[40px] xs:p-2">
        <h1 className="text-[40px] mb-2 xs:text-[22px] sm:text-[30px] md:text-[40px]">
          {t('home.tryitTitle1')}
          <span className="text-[#a238d5] inline-block relative">
            &nbsp;{t('home.tryitTitle2')}
          </span>
        </h1>
        <p className="font-semibold mt-0 text-[20px] mb-[20px] text-center xs:font-medium xs:text-[15px] xs:w-[300px] sm:w-[500px] md:w-full md:text-[20px] md:font-semibold">
          {t('home.tryitSubtitle')}
        </p>
      </div>
      <div className="flex justify-center">
        <AmazonLogo className="mr-10" width="10rem" height="5rem" />
        <AliexpressLogo width="10rem" height="4.5rem" />
      </div>
      <div className="md:flex md:justify-center xs:mx-2">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="relative mt-[15px] max-w-[800px]"
          >
            <FormField
              control={form.control}
              name="amazonUrl"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      className="font-normal box-border bg-[#36193D] text-white !text-sm w-full py-9 border-[3px] border-solid border-[rgba(255,255,255,1)] rounded-[20px] shadow-[0_4px_6px_rgba(0,0,0,0.1)] backdrop-blur-[10px] placeholder:text-white/60 xs:text-[0.875rem] sm:w-[25rem] sm:text-[1.25rem] md:w-[37.5rem]"
                      placeholder={t('common.productUrlPlaceholder')}
                      data-testid="tryit-url"
                      type="url"
                      id="amazon-url"
                      autoComplete="off"
                      required
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <Button
              icon={<BlinkStarIcon />}
              iconPosition="before"
              type="submit"
              text={t('home.tryitSubmit')}
              className="font-['Poppins'] right-[20px] top-[20px] text-white absolute not-italic font-medium leading-[normal] items-center bg-[#36193D] flex justify-center decoration-[none] backdrop-blur-[20px] border-none cursor-pointer text-[16px] p-[5px_20px_5px_15px] transition duration-[.2s] hover:scale-[1.01] xs:text-[0.75rem] sm:text-[1rem] hover:bg-gradient-to-r from-[#8628c0] to-[#a238d5] hover:rounded-[15px]"
            />
          </form>
        </Form>
      </div>
    </Animation>
  );
}

export default TryIt;
