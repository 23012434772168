/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import _size from 'lodash/size';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import { IoMdAdd } from 'react-icons/io';
import { LuLoader2 } from 'react-icons/lu';
import { PiWarningCircleFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';

import ImportTemplate from './ImportTemplate';
import HomepageImportTemplate from './ImportTemplate/Homepage';

import Button from '@/components/common/Button';
import { Switch } from '@/components/common/shadcn-ui/Switch';
import { useUpgradePlan } from '@/components/hooks/useUpgradePlan';
import ProductAppLayout from '@/components/products/Layout';
import LoadingSkeleton from '@/components/products/LoadingSkeleton';
import ProductHeader from '@/components/products/services/Header';
import PlanItem from '@/components/subscription/PlanItem';
import Video from '@/components/Video';
import { cn } from '@/lib/utils';
import { subscriptionPlans } from '@/shared/configs/subscriptionPlans';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/reduxStore';
import {
  useImportShopifyTemplateMutation,
  useLazyGetShopifyTemplateDataQuery,
} from '@/shared/slices/shopifySlice';
import { PlansLevel } from '@/shared/types/user';

function Import({ productServiceDetail, productDetail }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentProductId, shopifyHomepageImportTemplate } = useSelector(
    (state: RootState) => state?.products
  );

  const [showPurchasePlan, setShowPurchasePlan] = useState(false);
  const { user } = useSelector((state: RootState) => state?.auth);
  const [isAnnual, setIsAnnual] = useState(false);
  const [
    getShopifyTemplate,
    { data: shopifyTemplateData, isFetching: gettingShopifyTemplateData },
  ] = useLazyGetShopifyTemplateDataQuery();
  const shopifyDataLoading = gettingShopifyTemplateData || !shopifyTemplateData;
  const [
    importTemplateData,
    {
      data: importActionData,
      isLoading: postingToShopify,
      isSuccess: importActDone,
      reset: resetImportMutationState,
    },
  ] = useImportShopifyTemplateMutation();
  const { success: importActSuccess, message: importActMessage } =
    importActionData ?? {};
  const importActionSuccess = importActSuccess && !importActMessage;
  const [handleUpgradePlan, { isLoading: checkoutLoading }] = useUpgradePlan();
  const { code, productOnShopify, success, message } =
    shopifyTemplateData ?? {};
  const templateDataValid = !shopifyDataLoading && !code && success;

  const isProductAlreadyImport = !!_size(productOnShopify);
  const handleTemplateImport = () => {
    importTemplateData({
      productId: Number(currentProductId),
      homepage: shopifyHomepageImportTemplate ? 1 : 0,
    });
  };

  useEffect(() => {
    if (!currentProductId) return;
    resetImportMutationState();
    getShopifyTemplate(
      {
        productId: currentProductId,
        homepage: shopifyHomepageImportTemplate ? 1 : 0,
      },
      false
    );
  }, [currentProductId, user, shopifyHomepageImportTemplate]);

  const isUserPaid =
    (PlansLevel[user.plan] as unknown as number) >= PlansLevel.Pro;

  const highestPlans = subscriptionPlans.filter((plan) =>
    ['Pro', 'Lifetime'].includes(plan.name)
  );

  if (!productDetail?.urlValid) {
    return (
      <ProductAppLayout>
        <>
          <ProductHeader {...productServiceDetail} />
          <div className="w-full max-w-[800px]">
            <h3 className="flex items-center">
              <PiWarningCircleFill className="text-5xl mt-[2px] mr-2 text-amber-400" />
              {t('shopify.oops')}
            </h3>
            <p className="leading-6">{t('shopify.shopifyUrlInvalid')}</p>
          </div>
        </>
      </ProductAppLayout>
    );
  }

  if (!productDetail?.description) {
    return (
      <ProductAppLayout>
        <div className="w-full max-w-[800px]">
          <LoadingSkeleton />
        </div>
      </ProductAppLayout>
    );
  }

  const shopifyImportErrorCodes = [
    'shopScopesInvalid',
    'templateImportThemesError',
    'templateImportCreateProductError',
    'templateImportUnexpectedError',
  ];

  return (
    <ProductAppLayout>
      <>
        <ProductHeader {...productServiceDetail} />
        {!importActionSuccess && importActDone && (
          <div className="w-full max-w-[800px] border border-solid border-orange-400 px-5 py-5 rounded-lg bg-amber-100 bg-opacity-10 mb-10">
            <h3 className="flex items-center m-0">
              <PiWarningCircleFill className="text-5xl mt-[2px] mr-2 text-amber-400" />
              {t('shopify.oops')}
            </h3>
            {shopifyImportErrorCodes.includes(importActMessage) ? (
              <>
                <p className="leading-6 text-sm">
                  {t(`shopify.${importActMessage}`)}
                </p>
                {importActMessage === 'shopScopesInvalid' && (
                  <Video
                    showHeader={false}
                    animationClass="!p-0 my-6 flex w-[500px] h-[200px]"
                    video="shopifyInstruction"
                  />
                )}
              </>
            ) : (
              <p
                className="leading-6 text-sm"
                dangerouslySetInnerHTML={{
                  __html: importActMessage,
                }}
              />
            )}
          </div>
        )}
        {!templateDataValid && !!message && (
          <div className="w-full max-w-[800px]">
            <h3 className="flex items-center">
              <PiWarningCircleFill className="text-5xl mt-[2px] mr-2 text-amber-400" />
              {t('shopify.oops')}
            </h3>
            <p className="leading-6">{t(`shopify.${message}`)}</p>
          </div>
        )}
        {shopifyDataLoading && (
          <div className="w-full max-w-[800px] animate-pulse">
            <h3 className="flex items-center">
              <LuLoader2 className="animate-spin text-lg mt-[2px] mr-2 text-primary" />
              {t('shopify.justAMoment')}
            </h3>
            <p>{t('shopify.shopifyWaiting')}</p>
          </div>
        )}
        <div>
          {templateDataValid && (
            <div className="w-full max-w-[800px] flex flex-col items-center">
              {showPurchasePlan ? (
                <div>
                  <div className="flex flex-col items-center w-full max-w-[800px] text-center">
                    <div className="m-0 font-bold">
                      <h2 className="m-0">
                        {!isUserPaid && t('shopify.needUpgradeToPro')}
                      </h2>
                    </div>
                    <div className="text-sm text-zinc-400 my-3">
                      {!isUserPaid && t('shopify.needProPlanWarning')}
                    </div>
                    <div className="mt-10 flex flex-col items-center">
                      <div className="flex space-x-2 mb-5 items-center">
                        <span>{t('upgrade.monthly')}</span>{' '}
                        <Switch
                          variant="medium"
                          onCheckedChange={() => setIsAnnual((prev) => !prev)}
                        />
                        <span>{t('upgrade.annual')}</span>
                      </div>
                      <div className="flex items-center justify-between w-full sm:justify-around lg:w-full xs:flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap gap-5 lg:justify-center">
                        {highestPlans?.map((plan, index) => (
                          <PlanItem
                            key={plan.name}
                            isAnnualAccount={false}
                            isAnnualButton={isAnnual}
                            planIndex={index}
                            plan={plan}
                            handleUpgradePlan={handleUpgradePlan}
                            checkoutLoading={checkoutLoading}
                            isTrialEnable={false}
                            shopifyPurchase
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {importActionSuccess && (
                    <Confetti className="z-50" recycle={false} />
                  )}
                  <Button
                    text={
                      importActionSuccess
                        ? t('shopify.importSuccess')
                        : t('shopify.importToShopify')
                    }
                    className={cn(
                      'outline-none px-4 py-4 bg-lime-600 border-0 rounded-md text-white text-base shadow-md shadow-black/5 w-5/12 2xl:w-4/12 transition-all duration-200',
                      isProductAlreadyImport && 'hidden'
                    )}
                    icon={<IoMdAdd className="text-md" />}
                    disabled={isProductAlreadyImport}
                    iconPosition="before"
                    loading={postingToShopify}
                    loadingText={t('shopify.importing')}
                    onClick={() => {
                      if (!isUserPaid) {
                        setShowPurchasePlan(true);
                        return;
                      }
                      if (!user?.shopify) {
                        dispatch(
                          setProductsState({
                            shopifyConnectModal: true,
                          })
                        );
                        return;
                      }
                      handleTemplateImport();
                    }}
                  />

                  {!isProductAlreadyImport && (
                    <div className="my-6 w-full max-w-[800px]">
                      <div className="m-0 font-bold">
                        <h2 className="m-0">{t('shopify.addToShopify')}</h2>
                      </div>
                      <div className="text-sm text-zinc-400 mt-3">
                        {t('shopify.importSubtitle')}
                      </div>
                    </div>
                  )}
                  {shopifyHomepageImportTemplate ? (
                    <HomepageImportTemplate />
                  ) : (
                    <ImportTemplate />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </>
    </ProductAppLayout>
  );
}

export default Import;
