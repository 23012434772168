import Button from 'components/common/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/common/Card';
import { Input } from '@/components/common/Input';
import { Label } from '@/components/common/Label';

function ShopifyConnectSimple() {
  const { t } = useTranslation();
  const [shopName, setShopName] = useState<string | null>(null);
  return (
    <Card>
      <CardHeader>
        <CardTitle className="m-0 w-9/12 leading-6">
          {t('shopify.connectYourShopify')}
        </CardTitle>
        <CardDescription className="text-sm">
          {t('shopify.connectYourShopifySubTitle')}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Label htmlFor="name">{t('shopify.enterYourShopifyAddress')}</Label>
        <Label
          className="font-normal text-base w-full pt-3"
          htmlFor="shopify-name"
        >
          <Input
            className="p-0 m-0 outline-none text-sm w-8/12 border-defaultBlackLight/60 bg-white bg-opacity-10 empty:before:italic empty:before:text-zinc-500 text-black indent-2 placeholder:text-zinc-500"
            onBlur={(event) => {
              const { value } = event.currentTarget;
              setShopName(value ?? null);
            }}
            placeholder={t('shopify.enterYourShopifyAddressPlaceholder')}
          />
          <span
            className="mt-1 text-xs"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('shopify.shopifyConnectInstructions'),
            }}
          />
        </Label>
      </CardContent>
      <CardFooter className="flex justify-end mt-5">
        <Button
          onClick={async () => {
            if (!shopName) return;
            window.location.href = `${process.env.VITE_APP_API_URL}/shopify/auth?shop=${shopName}&token=${localStorage.getItem('productly')}`;
          }}
          className="border-none bg-primary px-4 text-sm rounded-sm py-2 hover:bg-shopify text-white transition-all"
        >
          {t('common.connect')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export default ShopifyConnectSimple;
