import React from 'react';

interface AliexpressLogoProps {
  className?: string;
  width?: string;
  height?: string;
}

function AliexpressLogo({ className, width, height }: AliexpressLogoProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="-8.636 -1.041 30.561 7.455"
      strokeWidth="10"
    >
      <g
        transform="matrix(0.486049, 0, 0, 0.486049, -44.181522, -70.223076)"
        id="layer1"
      >
        <g transform="translate(38.9315 83.3376) scale(.26458)" id="g43">
          <g id="g32">
            <path
              d="M195.5 262.8v-33.5h19.8v4.2h-15.7v10.3h14.1v4.2h-14.1v10.5h16.8v4.2h-20.9z"
              id="path12"
              fill="#e43225"
            />
            <path
              d="M237.2 262.8l-6.8-8.9-6.8 8.9h-4.8l9.3-11.9-9.8-12.3h5.4l6.7 9.2 6.8-9.2h5.3l-9.3 12.3 8.8 11.9z"
              id="path14"
              fill="#e43225"
            />
            <path
              d="M248.6 259.2v16.5h-4.1V251c0-6.3 4.8-13 12.3-13 7.6 0 13.3 4.8 13.3 12.7 0 7.7-5.8 13-12.4 13-3.2 0-7.5-1.4-9.1-4.5zm17.2-8.5c0-5.4-3.5-8.6-9.7-8.3-3 .1-7.6 2.3-7.2 10 .1 2.5 2.7 7.2 8.4 7.2 4.9 0 8.5-2.8 8.5-8.9z"
              id="path16"
              fill="#e43225"
            />
            <path
              d="M273.6 262.8v-24.2h4.1v2.6c2-2.3 5.1-3.1 8.4-3.1v4.4c-.5-.1-5.4-.7-8.4 5.7v14.7h-4.1z"
              id="path18"
              fill="#e43225"
            />
            <path
              d="M287.2 250.7c0-7 5-12.7 11.9-12.7 8.6 0 11.8 5.7 11.8 13v2h-19.2c.3 4.6 4.4 7 8.2 6.9 2.8-.1 4.7-.9 6.7-2.9l2.7 2.8c-2.5 2.4-5.7 4-9.6 4-7.3-.1-12.5-5.5-12.5-13.1zm11.6-8.6c-3.9 0-6.9 3.4-7.1 7.1h14.9c0-3.6-2.6-7.1-7.8-7.1z"
              id="path20"
              fill="#e43225"
            />
            <path
              d="M313 259.4l3-2.7c-.1 0 1.5 1.6 1.7 1.7.7.6 1.4 1 2.3 1.2 2.6.7 7.3.5 7.7-3.1.2-2-1.3-3.1-3-3.8-2.2-.8-4.6-1.1-6.8-2.1-2.5-1.1-4.1-3-4.1-5.8 0-7.3 10.4-8.5 15.1-5.1.2.2 2.5 2.3 2.4 2.3l-3 2.4c-1.5-1.8-2.9-2.7-6.1-2.7-1.6 0-3.8.7-4.2 2.4-.6 2.4 2.1 3.3 3.9 3.8 2.4.6 5 1 7.1 2.3 2.9 1.8 3.6 5.7 2.5 8.7-1.2 3.3-4.8 4.6-8 4.7-3.8.2-7.1-1-9.8-3.7-.2 0-.7-.5-.7-.5z"
              id="path22"
              fill="#e43225"
            />
            <path
              d="M334.1 259.4l3-2.7c-.1 0 1.5 1.6 1.7 1.7.7.6 1.4 1 2.3 1.2 2.6.7 7.3.5 7.7-3.1.2-2-1.3-3.1-3-3.8-2.2-.8-4.6-1.1-6.8-2.1-2.5-1.1-4.1-3-4.1-5.8 0-7.3 10.4-8.5 15.1-5.1.2.2 2.5 2.3 2.4 2.3l-3 2.4c-1.5-1.8-2.9-2.7-6.1-2.7-1.6 0-3.8.7-4.2 2.4-.6 2.4 2.1 3.3 3.9 3.8 2.4.6 5 1 7.1 2.3 2.9 1.8 3.6 5.7 2.5 8.7-1.2 3.3-4.8 4.6-8 4.7-3.8.2-7.1-1-9.8-3.7-.2 0-.7-.5-.7-.5z"
              id="path24"
              fill="#e43225"
            />
            <g id="g30">
              <path
                d="M353.6 238.6V236h-.9v-.5h2.4v.5h-.9v2.6z"
                id="path26"
                fill="#e43225"
              />
              <path
                d="M358.1 238.6v-2.4l-.9 2.4h-.2l-.9-2.4v2.4h-.5v-3.1h.8l.8 2.1.8-2.1h.8v3.1z"
                id="path28"
                fill="#e43225"
              />
            </g>
          </g>
          <g id="g41">
            <path
              d="M167.7 262.8l-3-8h-16.2l-3 8h-4.3l13-33.5h4.7l12.9 33.5zm-11.3-28.7l-6.1 16.6H163z"
              id="path34"
              fill="#f7971d"
            />
            <path d="M174.5 262.8v-33.5h4.2v33.5z" id="path36" fill="#f7971d" />
            <path d="M185 262.8v-23.7h4.2v23.7z" id="path38" fill="#f7971d" />
            <path
              id="XMLID_1_"
              d="M193.2 231.4v-.4c-3.2-.1-5.8-2.7-5.9-5.9h-.6c-.1 3.2-2.7 5.8-5.9 5.9v.4c3.2.1 5.8 2.7 5.9 5.9h.6c.1-3.2 2.7-5.8 5.9-5.9z"
              fill="#f7971d"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AliexpressLogo;
