/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import { AddProductModal } from 'components/modals/AddProduct';
import _drop from 'lodash/drop';
import _head from 'lodash/head';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  amazonAsinExtract,
  checkGenerateContent,
  getProductCode,
} from '../../utils/helpers';

import './app.css';
import EcomApplication from '@/components/app/application';
import GenerateFromScratch from '@/components/app/scratch';
import Import from '@/components/app/shopify/Import';
import Header from '@/components/Header';
import ShopifyConnectModal from '@/components/modals/ShopifyConnect';
import ShopifyMessageModal from '@/components/modals/ShopifyMessage';
import ProductInfo from '@/components/products/ProductInfo';
import ProductsList from '@/components/products/ProductsList';
import Services from '@/components/products/services';
import ServicesMenu from '@/components/products/services/ServicesMenu';
import ServicesMenuMobile from '@/components/products/services/ServicesMenuMobile';
import { cn } from '@/lib/utils';
import { FeatureFlags, displayFeature } from '@/shared/configs/featureFlags';
import {
  baseServices,
  getServices,
  servicesMap,
} from '@/shared/configs/servicesMap';
import { setApplicationState } from '@/shared/reducers/applicationReducer';
import { setFromScratch } from '@/shared/reducers/fromScratchReducer';
import { setProductsState } from '@/shared/reducers/productsReducer';
import { RootState } from '@/shared/rootReducers';
import { useAddProductMutation } from '@/shared/slices/addProductSlice';
import { useLazyGetGenerateNewTokenQuery } from '@/shared/slices/authSlice';
import {
  getProductServiceDescription,
  useGetProductDetailQuery,
  useGetProductReviewsQuery,
  useGetServiceDetailQuery,
  useLazyGetProductDetailQuery,
  useLazyGetProductListQuery,
} from '@/shared/slices/productSlice';
import { Service } from '@/shared/types/service';
import { PlansLevel } from '@/shared/types/user';

function MemberArea() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state?.auth);
  const {
    page,
    limit,
    currentProductId,
    stock,
    generatedProducts,
    regeneratedProducts,
    products,
    shopifyImport,
    shopifyHomepageImportTemplate,
  } = useSelector((state: RootState) => state?.products);
  const { active: fromScratchActive } = useSelector(
    (state: RootState) => state?.fromScratch
  );
  const { active: applicationActive } = useSelector(
    (state: RootState) => state?.application
  );
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [regenerateProduct, setRegenerateProduct] = useState<any>(null);
  const location = useLocation();
  const [showAddProductForm, setShowAddProductForm] = useState(
    location.state?.showModal || false
  );
  const [initialModalVisible, setInitialModalVisible] = useState(true);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [scrappingData, setScrappingData] = useState({});
  const [liveUpdate, setLiveUpdate] = useState({});
  const [newProduct, setNewProduct] = useState<{
    name: string;
    amazonUrl: string;
    reGenerate?: boolean;
    lang?: string;
  }>({
    name: '',
    amazonUrl: location.state?.amazonUrl || '',
    reGenerate: false,
  });
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [services, setServices] = useState<Service[]>([]);

  const [getProductsList] = useLazyGetProductListQuery();
  const [getProductDetail] = useLazyGetProductDetailQuery();

  const [getAddProductssData, { isError: addProductError }] =
    useAddProductMutation();
  const [generateNewToken] = useLazyGetGenerateNewTokenQuery();

  const productIdInvalid = currentProductId === 0;
  const { currentData: productServiceDetail } = useGetServiceDetailQuery(
    {
      productId: currentProductId,
      service: selectedService,
    },
    {
      skip: productIdInvalid || !selectedService,
      ...liveUpdate,
    }
  );
  const { currentData: productDetail } = useGetProductDetailQuery(
    {
      productId: currentProductId,
    },
    {
      skip: productIdInvalid,
      ...liveUpdate,
    }
  );
  const { data: reviews } = useGetProductReviewsQuery(
    {
      productId: currentProductId,
    },
    {
      skip: productIdInvalid,
      ...liveUpdate,
    }
  );

  useEffect(() => {
    // Check if there's an Amazon URL in the local storage
    const storedAmazonUrl = localStorage.getItem('amazonUrl');
    if (storedAmazonUrl) {
      // Set the Amazon URL from the local storage to the state
      setNewProduct((prevProduct) => ({
        ...prevProduct,
        amazonUrl: storedAmazonUrl,
      }));
      // Show the add product form
      setShowAddProductForm(true);
      // Delete the Amazon URL from the local storage
      localStorage.removeItem('amazonUrl');
    }

    // The existing logic
    if (location.state?.showModal && initialModalVisible) {
      setShowAddProductForm(true);
      setNewProduct((prevProduct) => ({
        ...prevProduct,
        amazonUrl: location.state.amazonUrl,
      }));
      setInitialModalVisible(false);

      // Remove the showModal and amazonUrl properties from the state
      navigate(location.pathname, {
        state: {
          ...location.state,
          showModal: undefined,
          amazonUrl: undefined,
        },
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, initialModalVisible, navigate]);

  useEffect(() => {
    if (!page || !products) return;
    // If page load, find the first valid product and select it, this mean will not select the error generated product
    const productWithDescription = products.find(
      (product: any) => product.description !== null && product.generated
    );
    if (productWithDescription && page === 1) {
      getProductDetail({ productId: productWithDescription.id });
      dispatch(
        setProductsState({ currentProductId: productWithDescription.id })
      );
      setSelectedService('description');
    }
  }, [products, page]);

  useEffect(() => {
    if (!currentProductId || !productDetail) return;
    const {
      generate_objections: generateObjections,
      generate_benefits: generateBenefits,
      lang: productLanguage,
      urlValid,
    } = productDetail ?? {};

    if (!urlValid) {
      return;
    }
    if (generateObjections && generateBenefits) {
      dispatch(setFromScratch({ showScratchButton: true }));
    }
    if (productLanguage?.length > 0) {
      dispatch(setFromScratch({ language: productLanguage }));
    }

    const fullContentGenerated = checkGenerateContent({
      baseServices,
      servicesMap,
      productDetail,
    });

    if (fullContentGenerated) {
      setLiveUpdate({});
      const productGenerating = products?.find(
        ({ scraping, generated }) => scraping && !generated
      );
      // Let's update finish state for done generated product
      if (productGenerating?.id === currentProductId && products) {
        const productDescFromCache = getProductServiceDescription(
          productGenerating?.id
        );
        const updateProduct = {
          ...productGenerating,
          ...productDescFromCache,
          scraping: 0,
          generated: 1,
        };
        dispatch(
          setProductsState({
            products: [
              updateProduct,
              ...products.filter(({ id }) => id !== productGenerating.id),
            ],
          })
        );
      }
    } else {
      setLiveUpdate({
        pollingInterval: 3000,
      });
    }
  }, [productDetail, currentProductId]);

  const aliexpressFlag = displayFeature({
    featureFlag: FeatureFlags?.PRODLY_390_ALIEXPRESS,
  });
  const handleAddProduct = async (
    e: React.FormEvent<HTMLFormElement> | { preventDefault: () => void }
  ) => {
    e?.preventDefault();

    let productCode;
    if (aliexpressFlag) {
      const productCodeByURL = getProductCode(newProduct.amazonUrl);
      productCode = productCodeByURL?.productCode;
    } else {
      productCode = amazonAsinExtract(newProduct.amazonUrl);
    }

    if (!productCode) {
      setErrorMessage(
        aliexpressFlag
          ? t('memberArea.errorUnsupportPlatform')
          : t('memberArea.errorAmazonUrlInvalid')
      );
      return;
    }

    if (productCode?.asin_code) {
      setErrorMessage(
        t('memberArea.errorAsinCodeInvalid', {
          asinCode: productCode?.asin_code,
        })
      );
      return;
    }

    setShowAddProductForm(false);

    try {
      const isProductFailed = products?.find(
        (product) =>
          product?.amazon_url === newProduct?.amazonUrl && !product?.generated
      );
      if (isProductFailed) {
        setIsGenerating(true);
      }
      setIsSubmitting(true);

      const addProduct = await getAddProductssData(newProduct).unwrap();

      if (addProductError) {
        setIsSubmitting(false);
        toast.error(t('common.somethingWrong'));
        return;
      }

      if (addProduct?.notFound) {
        setIsSubmitting(false);
        setShowAddProductForm(true);
        setErrorMessage(
          aliexpressFlag
            ? t('memberArea.errorProductUrlNotfound')
            : t('memberArea.errorAmazonUrlNotfound')
        );
        return;
      }
      if (addProduct?.success && !isProductFailed) {
        setScrappingData(addProduct);
        dispatch(
          setProductsState({
            currentProductId: addProduct.id,
          })
        );
        setSelectedService('description');
      }
      if (!addProduct?.success && isProductFailed) {
        setScrappingData({
          ...addProduct,
          regenerateProductFailed: true,
        });
      }
      if (addProduct?.success && isProductFailed) {
        setScrappingData({
          ...addProduct,
          regenerateProductFailed: false,
        });
      }
      getProductsList({ page: 1, limit, reset: true });
      setFormSubmitted(true);
      setNewProduct({ name: '', amazonUrl: '' });
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error adding product:', error);
      toast.error(t('common.somethingWrong'));
      setIsSubmitting(false); // Ensure isSubmitting is set to false even if an error occurs
    }
  };

  const handleRetryGenerate = () => {
    setNewProduct({
      ...newProduct,
      amazonUrl: regenerateProduct?.amazon_url,
      name: regenerateProduct?.name,
      lang: regenerateProduct?.lang,
      reGenerate: true,
    });
    setRegenerateProduct(null);
  };

  useEffect(() => {
    if (!newProduct?.reGenerate) return;
    handleAddProduct({ preventDefault: () => {} });
  }, [newProduct]);

  useEffect(() => {
    dispatch(setApplicationState({ active: false }));
    generateNewToken(undefined, false);
    getProductsList({ page: 1, limit, reset: true });
  }, []);

  useEffect(() => {
    if (!user) return;
    setServices(getServices(user.plan));

    if (user?.shopify) {
      navigate('/app');
    }
  }, [user, formSubmitted]);

  const importToShopifyFeature = displayFeature({
    featureFlag: FeatureFlags?.PRODLY_336_IMPORT_TO_SHOPIFY_20240612,
  });
  const isUserPaid =
    (PlansLevel[user.plan] as unknown as number) >= PlansLevel.Pro;
  const shopifyConnectModal =
    importToShopifyFeature && !user?.shopify && isUserPaid;

  const showMainService =
    !fromScratchActive &&
    !applicationActive &&
    !shopifyImport &&
    !shopifyHomepageImportTemplate;
  const showShopifyImport =
    shopifyImport && !fromScratchActive && !shopifyHomepageImportTemplate;
  const showApplication = applicationActive && !fromScratchActive;
  return (
    <div>
      <div className="absolute w-full h-[1400px] pointer-events-none bg-index-gradient-background" />
      <div
        className={clsx(
          isSubmitting ||
            showAddProductForm ||
            regenerateProduct ||
            formSubmitted
            ? 'blur-sm'
            : 'blur-none'
        )}
      >
        <Header />
        {user ? (
          <div className="flex xs:flex-wrap lg:flex-nowrap z-[2] justify-center container">
            <ServicesMenuMobile
              services={services}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              productDetail={productDetail}
              reviews={reviews}
            />
            <ServicesMenu
              services={services}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              productDetail={productDetail}
              reviews={reviews}
            />
            {showMainService && (
              <Services
                productServiceDetail={productServiceDetail}
                selectedService={selectedService!}
                productDetail={productDetail}
                user={user}
                reviews={reviews!}
              />
            )}
            {(shopifyHomepageImportTemplate || showShopifyImport) && (
              <Import
                productServiceDetail={productServiceDetail}
                productDetail={productDetail}
              />
            )}
            {fromScratchActive && (
              <GenerateFromScratch
                productDetail={productDetail}
                setSelectedService={setSelectedService}
              />
            )}
            {showApplication && <EcomApplication />}

            <div
              className={cn(
                'xs:mx-6 xs:mb-6 lg:m-0',
                products &&
                  products?.length <= 0 &&
                  'sm:min-w-full lg:min-w-[300px] 2xl:min-w-[457px]'
              )}
            >
              <div className="w-full">
                <ProductInfo
                  user={user}
                  stock={stock ?? 0}
                  generatedProducts={generatedProducts ?? 0}
                  regeneratedProducts={regeneratedProducts ?? 0}
                  setShowAddProductForm={setShowAddProductForm}
                />
                <ProductsList
                  setRegenerateProduct={setRegenerateProduct}
                  setSelectedService={setSelectedService}
                />
                <AddProductModal
                  showAddProductForm={showAddProductForm}
                  isSubmitting={isSubmitting}
                  isGenerating={isGenerating}
                  setShowAddProductForm={setShowAddProductForm}
                  handleAddProduct={handleAddProduct}
                  setNewProduct={setNewProduct}
                  newProduct={newProduct}
                  errorMessage={errorMessage!}
                  formSubmitted={formSubmitted}
                  regenerateProduct={regenerateProduct}
                  setFormSubmitted={setFormSubmitted}
                  setRegenerateProduct={setRegenerateProduct}
                  scrappingData={scrappingData}
                  handleRetryGenerate={handleRetryGenerate}
                />
                {shopifyConnectModal && (
                  <>
                    <ShopifyConnectModal />
                    {/* The ShopifyMessageModal will open after user go back from shopify/callback */}
                    <ShopifyMessageModal />
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}

export default MemberArea;
