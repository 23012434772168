import { format, getMonth, setMonth } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import { find } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LineChart } from './LineChart';

import {
  RadioButtonGroup,
  RadioButtonGroupItem,
} from '@/components/Buttons/RadioButtonGroup';
import { Label } from '@/components/common/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/common/shadcn-ui/Select';
import TextLoadingSkeleton from '@/components/common/TextLoadingSkeleton';
import { useGetUserEarningQuery } from '@/shared/slices/affiliateSlice';
import { DashboardProps } from '@/shared/types/affiliate';

const periods = [
  { value: 'this_month', text: 'This month' },
  { value: 'last_month', text: 'Last month' },
  { value: 'last_90_days', text: 'Last 90 days' },
];

export function Dashboard({ stats, period, onPeriodChange }: DashboardProps) {
  const {
    i18n: { language },
  } = useTranslation();

  const [earningMonth, setEarningMonth] = useState('all');
  const selectedPeriod = find(periods, { value: period })?.text;
  const dateLang = language === 'en' ? enGB : fr;

  const currentMonth = getMonth(new Date()) + 1;
  const monthList = Array(currentMonth)
    .fill(0)
    .map((_, monthNumber) => ({
      text: format(setMonth(new Date(), monthNumber), 'LLLL', {
        locale: dateLang,
      }),
      value: monthNumber,
    }));

  const { data: earningData, isLoading: earningLoading } =
    useGetUserEarningQuery({
      month: earningMonth,
    });

  const { totalEarning, alreadyPaid, currentBalance } =
    earningData?.earning ?? {};
  return (
    <div className="affiliate-dashboard">
      <h1 className="account-headd xs:!px-6 md:!px-60">Affiliate Dashboard</h1>
      <div className="xs:px-6 sm:px-16">
        <div className="earning-stats">
          <div className="md:flex xs:block items-center justify-between">
            <h4 className="p-0 m-0">Your Earning</h4>
            <Select
              defaultValue="all"
              onValueChange={(value) => setEarningMonth(value)}
            >
              <SelectTrigger className="w-[180px] xs:mt-2 items-center p-2 bg-white border-none rounded-lg text-primary text-xs font-medium cursor-pointer">
                <SelectValue placeholder="Pick a month" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Time</SelectItem>
                {monthList.map(({ text, value }) => (
                  <SelectItem value={String(value)}>{text}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="p-5 text-sm [&>_p]:m-0 [&>_p]:leading-7 mt-2 bg-white bg-opacity-10 xs:w-full md:w-auto rounded-lg border border-solid border-grayLight">
            {earningLoading ? (
              <TextLoadingSkeleton />
            ) : (
              <>
                <p>
                  Total earning:{' '}
                  <span className="font-bold text-primary">
                    €{totalEarning ?? 0}
                  </span>
                </p>
                <p>
                  Payout:{' '}
                  <span className="font-bold text-primary">
                    €{alreadyPaid ?? 0}
                  </span>
                </p>
                <p>
                  Current balance:{' '}
                  <span className="font-bold text-primary">
                    €{currentBalance ?? 0}
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="mt-10">
          <div className="lg:flex md:block items-center justify-between my-6">
            <h4 className="p-0 m-0">Invited statistic</h4>
            <RadioButtonGroup
              defaultValue="default"
              className="flex gap-0 md:mt-2"
              onValueChange={onPeriodChange}
            >
              {periods.map((item) => (
                <RadioButtonGroupItem
                  key={item.value}
                  value={item.value}
                  id={item.value}
                  className="px-3 py-2 mr-0 first:rounded-r-none last:rounded-l-none even:rounded-none"
                  checked={item.value === period}
                >
                  <Label className="text-xs" htmlFor="r1">
                    {item.text}
                  </Label>
                </RadioButtonGroupItem>
              ))}
            </RadioButtonGroup>
          </div>
          <p className="text-xs text-gray-500">
            Bellow chart will show you how is your affiliate program performance
            in {selectedPeriod ?? 'this_month'}
          </p>
          {!stats ? (
            <TextLoadingSkeleton />
          ) : (
            <LineChart {...stats} period={period} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
