import { nanoid } from '@reduxjs/toolkit';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdAddShoppingCart } from 'react-icons/md';
import { useSelector } from 'react-redux';

import ImportProductLongBenefitsImage from './ImportProductLongBenefitsImage';

import { FormFieldsSettingProps } from '.';

import Button from '@/components/common/Button';
import TextLoadingSkeleton from '@/components/common/TextLoadingSkeleton';
import EditableText from '@/components/EditableText';
import GroupDeletable from '@/components/GroupDeletable/GroupDeletable';
import { RootState } from '@/shared/reduxStore';
import { useGetShopifyTemplateDataQuery } from '@/shared/slices/shopifySlice';

interface ImportProductLongBenefitsProps {
  fields: Record<string, any>[];
  onInputChange: ({ value, id, name }: any) => void;
}

function ImportProductLongBenefits({
  fields,
  onInputChange,
}: ImportProductLongBenefitsProps) {
  const { t } = useTranslation();
  const { currentProductId, shopifyHomepageImportTemplate } = useSelector(
    (state: RootState) => state?.products
  );
  const { data: shopifyTemplateData, isFetching: gettingShopifyTemplateData } =
    useGetShopifyTemplateDataQuery(
      {
        productId: currentProductId,
        homepage: shopifyHomepageImportTemplate ? 1 : 0,
      },
      {
        skip: !currentProductId,
      }
    );

  const { longBenefits } = shopifyTemplateData ?? [];
  return (
    <div>
      {longBenefits?.length <= 0 && <TextLoadingSkeleton />}
      {!gettingShopifyTemplateData &&
        longBenefits?.map((benefit: Record<string, any>, index: number) => {
          const titleField = find(fields, {
            name: `longBenefitsTitle${index}`,
          }) as FormFieldsSettingProps;
          const contentField = find(fields, {
            name: `longBenefitsContent${index}`,
          }) as FormFieldsSettingProps;
          const imageField = find(fields, {
            name: `longBenefitsImage${index}`,
          }) as FormFieldsSettingProps;
          return (
            <GroupDeletable groupId={benefit.id} key={nanoid()}>
              <div className="col-span-12 my-10 grid w-full grid-cols-12 items-center justify-between gap-3">
                {index % 2 === 0 && (
                  <ImportProductLongBenefitsImage
                    {...imageField}
                    onInputChange={onInputChange}
                  />
                )}
                <div className="col-span-12 mx-auto w-10/12 lg:col-span-5 lg:max-w-md">
                  <div className="flex h-full flex-col items-center justify-center">
                    <div className="w-full">
                      <EditableText
                        {...titleField}
                        onInputChange={onInputChange}
                      />
                    </div>
                    <div className="w-full">
                      <EditableText
                        {...contentField}
                        onInputChange={onInputChange}
                      />
                    </div>
                  </div>
                </div>
                {index % 2 === 1 && (
                  <ImportProductLongBenefitsImage
                    {...imageField}
                    onInputChange={onInputChange}
                  />
                )}
              </div>
            </GroupDeletable>
          );
        })}
    </div>
  );
}

export default ImportProductLongBenefits;
